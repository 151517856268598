<template>
  <section class="gold-purchase">
    {{ message }}
  </section>
</template>

<script>
export default {
  data () {
    return {
      message: ''
    };
  },
  created() {
    this.$bobwinHub.$on('CheckStripeSessionReceived', this.CheckStripeSessionReceived);
    const clientReferenceId = this.$route.query.session;
    this.CallHub({task: 'CheckStripeSession', callback: 'CheckStripeSessionReceived', data:clientReferenceId});
  },
  beforeDestroy () {
    this.$bobwinHub.$off('CheckStripeSessionReceived', this.CheckStripeSessionReceived);
  },
  methods: {
    CheckStripeSessionReceived(response) {
      if (response.result == "success") {
        this.message = this.$t('successPayment');
        const user = JSON.parse(response.userData);
        this.$store.state.user.gold = user.gold; 
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      } else {
        this.message = this.$t('unsuccessPayment');
      }
      window.console.log(response);
    }
  }
}
</script>